import React from 'react';
import {getOutlet, useOutletSetter, useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import {Button} from 'antd';
import * as User from 'rev.sdk.js/Actions/User';
import * as AppActions from '../../AppActions';
import {L} from '../../AppActions';

function isAdmin(user) {
  const allowed = ['admin', 'management'];

  for (const grp of allowed) {
    if (user.grp.indexOf(grp) > -1) {
      return true;
    }
  }
  return false;
}

function withLoginRequired(NextComp, options) {
  const {
    admin,
    SiteNavBar,
    renderLoginRequired = null,
    renderAccessRequired = null,
  } = options;

  function LoginRequired(props) {
    const {location} = props;
    const showLoginModal = useOutletSetter('login-modal');
    let hasNavBar = true;

    if (location?.pathname.indexOf('admin') > -1) {
      hasNavBar = false;
    }

    return (
      <>
        {hasNavBar && SiteNavBar && <SiteNavBar location={location} />}
        {renderLoginRequired ? (
          renderLoginRequired(options)
        ) : (
          <InnerWrapper>
            <img src="/images/Logo.png" width={100} />
            <h1 style={{marginTop: 20, marginBottom: 10}}>Admin</h1>
            <Button onClick={() => showLoginModal({admin})}>
              {L('login', undefined, {allCap: true})}
            </Button>
          </InnerWrapper>
        )}
      </>
    );
  }

  function AccessRequired(props) {
    return (
      <>
        {renderAccessRequired ? (
          renderAccessRequired(options)
        ) : (
          <InnerWrapper>
            <h1>Access Required</h1>
            <Button onClick={() => User.logout()}>Logout</Button>
          </InnerWrapper>
        )}
      </>
    );
  }

  class Wrapper extends React.Component {
    constructor(props) {
      super(props);

      this._unregister = getOutlet('user').register(() => {
        this.forceUpdate();
      });
    }
    componentWillUnmount() {
      this._unregister();
    }

    render() {
      const user = getOutlet('user').getValue();

      if (user) {
        if (!admin) {
          return <NextComp user={user} {...this.props} />;
        }

        if (admin && isAdmin(user)) {
          return AppActions.adminCanAccess(user, {...this.props}) ? (
            <NextComp user={user} {...this.props} />
          ) : (
            <AccessRequired admin={admin} {...this.props} />
          );
        }
      }

      return <LoginRequired admin={admin} {...this.props} />;
    }
  }

  Wrapper.displayName = NextComp.displayName;
  return Wrapper;
}

const InnerWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-80px);
`;

export {withLoginRequired};
