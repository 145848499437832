const en = require('rev.sdk.js/Locale/en');

const labels = {
  ...en.labels,
  intro: 'Introduction',
  spec: 'Specification',
  remark: 'Remark',
  share_link: 'Share Link',
  confirm: 'Confirm',
  customer_support: 'Contact',
  customer_support_title: 'About what',
  customer_support_content: 'Detailed description',
  name: 'Name',
  phone: 'Phone',
  email: 'Email',
  updated: 'Updated time',
  next_article: 'Next Article',
  prev_article: 'Previous Article',
  cart: 'Cart',
  cart_add: 'Add To Cart',
  cart_add_error_message_items_limit: '超過購物車購買上限，請分批購買en',
  cart_add_error_message:
    '發生錯誤, 請至個人頁面按下「清除購物車」再重新嘗試en',
  cart_clear: 'Clear Cart',
  cart_clear_success: '清除購物車成功en',
  cart_clear_failure: '清除購物車失敗，請與客服人員聯繫en',
  cart_empty_content: '購物車尚無商品！歡迎選購加入購物車！en',
  buy_now: '立即購買en',
  checkout_info: '寄送資訊en',
  cart_empty: 'Cart Empty',
  sale_start_time: '供應開始時間en',
  sale_end_time: '供應結束時間en',

  // NB
  MainPage: 'Home Page',
  Announcement: 'News',
  Product: 'Product',
  Member: 'Account Management',
  Video: 'Video',
  Setting: 'Setting',
  Logout: 'Log Out',
  nav_news: 'News',
  nav_products: 'Product Info',
  nav_videos: 'Video',
  nav_profile: 'Account',
  nav_reservations: 'Wishlist',
  please_choose_page: 'Please select the page',
  dealers_login_first: 'Welcome',
  title: 'Title',
  label: 'Type',
  created: 'Date',
  success: 'success',
  err_plz_try_again: 'Please try again later',
  Categories: 'Categories',
  Model: 'SKU',
  Name: 'Style Code',
  Gender: 'Gender',
  Size: 'Size',
  Width: 'Width',
  Pattern: 'Fit',
  Marketing: 'Key Look',
  Note: 'Remarks',

  plz_input_correct_amount: 'Please input correct amount!',
  min_amount_for_shoe: 'Min amount for shoe is 12!',
  min_amount_for_else: 'Min amount is 6!',
  all_reservations: 'Wishlist',
  reservation_not_exist: 'Wishlist not exists!',
  product_not_selected: "Product hasn't been selected yet",
  add_to_reservation: 'Add to Wishlist',
  select: 'Select',
  amount: 'QTY',
  rename: 'Rename',
  new_name: 'New Wishlist',
  remove: 'Delete',
  detail: 'Detail',
  reload: 'Refresh',
  create_new_reservation: 'Create',
  are_you_sure: 'Are you sure？',
  download: 'Download',
  click_to_download: 'Click to download',
  reservation: 'Wishlist',
  total: 'Total',
  percentage_note:
    'The percentage below shows the portion of each porduct category',

  product_videos: 'Product Video',
  login: 'Login',
  id: 'ID',
  password: 'Password',

  plz_input_id: 'Please enter your ID',
  plz_input_pwd: 'Please enter your password',
  back_to_articles: 'Back',
  currency_type: 'HK',

  personal_info: 'Account',
  corp_name: 'Company Name',
  account_name: 'ID',

  retail_price: 'Retail Price',
  qty: 'QTY',
  subtotal: 'Subtotal',
  items: 'items',

  rename_wishlist: 'Rename Wishlist',
  ok: 'OK',

  upload_xlsx_file: 'Upload xlsx',
  upload_xlsx_to_database: 'Upload to e-Catalog',
  export_excel: 'Export Excel',
  download_excel_template: 'Download Excel Template',
};

module.exports = {labels};
