import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import LoginForm from './LoginForm';

function LoginModal(props) {
  const {title = '', adminTitle = '', renderExtra = null} = props;
  const [info, setVisible] = useOutlet('login-modal');
  const visible = !!info;
  let admin = info && info.admin;

  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={450}
      visible={visible}
      onOk={() => {
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
      }}
      destroyOnClose={true}>
      <Wrapper>
        <Center>
          <LoginForm
            admin={admin}
            formTitle={title}
            adminFormTitle={adminTitle}
            onSucceed={() => setVisible(false)}
            renderExtra={renderExtra}
            closeModal={() => setVisible(false)}
          />
        </Center>
      </Wrapper>
    </Ant.Modal>
  );
}

const Wrapper = styled.div`
  padding: 40px;
`;

const Center = styled.div`
  max-width: 300px;
  width: 100%;
  margin: 0 auto;

  & > h2 {
    margin-bottom: 20px;
    font-size: 25px;
    color: #000000;
    letter-spacing: 3px;
    line-height: 25px;
  }

  & .input-style {
    border: 1px solid #e5e5ea;
    border-radius: 5px;
    padding: 10px 15px;
    height: 45px;
  }

  & .button-style {
    box-shadow: 0 5px 8px 0 lightgray;
    border-radius: 10px;
    padding: 9px;
    width: 100%;
    height: 40px;
    font-weight: 500;
    font-size: 15px;
  }
`;

const Container = styled.div`
  padding: 10px 0px;
  display: flex;
  align-items: center;

  & .text-style {
    padding: 0px;
    line-height: 20px;
    font-size: 13px;
  }
`;

export default LoginModal;
