const tw = require('rev.sdk.js/Locale/tw');

const labels = {
  ...tw.labels,
  intro: '介紹',
  spec: '規格',
  remark: '備註',
  share_link: '複製分享連結',
  confirm: '確認',
  customer_support: '聯絡我們',
  customer_support_title: '問題類型',
  customer_support_content: '詢問內容',
  name: '姓名',
  phone: '聯絡電話',
  email: '信箱',
  updated: '更新時間',
  next_article: '上一篇',
  prev_article: '下一篇',
  cart: '購物車',
  cart_add: '加入購物車',
  cart_add_error_message_items_limit: '超過購物車購買上限，請分批購買',
  cart_add_error_message: '發生錯誤, 請至個人頁面按下「清除購物車」再重新嘗試',
  cart_clear: '清除購物車',
  cart_clear_success: '清除購物車成功',
  cart_clear_failure: '清除購物車失敗，請與客服人員聯繫',
  cart_empty_content: '購物車尚無商品！歡迎選購加入購物車！',
  buy_now: '立即購買',
  checkout_info: '寄送資訊',
  cart_empty: 'Cart Empty',
  sale_start_time: '供應開始時間',
  sale_end_time: '供應結束時間',
  all_categories: '全部',

  // NB
  MainPage: '首頁',
  Announcement: '公告',
  Product: '商品',
  Member: '會員',
  Video: '影片',
  Setting: '設定',
  Logout: '登出',
  nav_news: '最新消息',
  nav_products: '商品型錄',
  nav_videos: '影片資訊',
  nav_profile: '個人資訊',
  nav_reservations: '預購清單',
  please_choose_page: '請選擇控制頁面',
  dealers_login_first: '經銷商請先登入',
  title: '標題',
  label: '類別',
  created: '創建時間',
  success: '成功',
  err_plz_try_again: '發生錯誤，請稍後再試一次',
  Categories: '分類目錄',
  Model: '商品型號',
  Name: '商品名稱',
  Gender: '性別',
  Size: '尺寸',
  Width: '寬度',
  Pattern: '版型',
  Marketing: '行銷款',
  Note: '備註',

  plz_input_correct_amount: '請輸入正確的數量',
  min_amount_for_shoe: '鞋類最小數量為12!',
  min_amount_for_else: '最小數量為6!',
  all_reservations: '所有預購單',
  reservation_not_exist: '預約單不存在',
  product_not_selected: '尚未指定商品',
  add_to_reservation: '加入預購單',
  select: '請選擇',
  amount: '數量',
  rename: '重新命名',
  new_name: '新預約單名稱',
  remove: '刪除',
  detail: '詳情',
  reload: '重新整理',
  create_new_reservation: '創建新預約單',
  are_you_sure: '確定要執行此動作嗎？',
  download: '下載',
  click_to_download: '點此下載',
  reservation: '預約單',
  total: '總計',
  percentage_note: '下方百分比為該類別預購總金額佔整筆預購單額之比例',

  product_videos: '產品影片',
  login: '登入',
  id: '帳號',
  password: '密碼',

  plz_input_id: '請輸入帳號',
  plz_input_pwd: '請輸入密碼',

  back_to_articles: '返回所有文章',
  currency_type: 'NT',

  personal_info: '個人資訊',
  corp_name: '公司名稱',
  account_name: '帳號',

  retail_price: '單價',
  qty: '數量',
  subtotal: '小計',
  items: '品項',

  rename_wishlist: '重新替預約單命名',
  ok: '確認',

  upload_xlsx_file: '上傳xlsx檔案',
  upload_xlsx_to_database: '上傳xlsx至資料庫',
  export_excel: '下載 excel 檔案',
  download_excel_template: '下載 excel 模板',
};

module.exports = {labels};
